export enum PlayerExternalEvent {
  Started = 'started',
  Paused = 'paused',
  RequireLogin = 'requireLogin',
  RequireSubscription = 'requireSubscription',
  Aborted = 'aborted',
  Completed = 'completed',
  /** This event fires whenever a user makes progress in the game */
  Progress = 'progress',
  /** Fires when a user makes a move (fill a cell, find a word, flip a cell) */
  Move = 'move',
  Closed = 'closed',
  AdBreak = 'adBreak',
  /** send dynamic form or campaign data */
  Track = 'track',
  Share = 'share',
  /** after an RSS puzzle, visit the url of the article */
  VisitUrl = 'visitUrl',
  AllEvents = 'all',
  // Backwards compatibility
  OnStateChange = 'onStateChange',
}

export interface ExternalShareEventData {
  text?: string;
}

export interface ExternalTimerData {
  ms: number;
}

export interface ExternalURLData {
  url?: string;
  target?: 'self' | 'blank';
}

export interface ExternalTrackEvent {
  ms: number;
  eventName: string;
  data: any;
}

export enum GameResultDataUnit {
  Milliseconds = 'milliseconds',
  Points = 'points',
  Attempts = 'attempts',
  Rank = 'rank',
  Solution = 'solution',
}

type GameResultDataSolution = {
  value: string;
  unit: GameResultDataUnit.Solution;
};

type GameResultDataRank = {
  value: string;
  unit: GameResultDataUnit.Rank;
};

type GameResultDataPoints = {
  value: number;
  unit: GameResultDataUnit.Points;
};

type GameResultDataAttempts = {
  value: number;
  unit: GameResultDataUnit.Attempts;
};

type GameResultDataMilliseconds = {
  value: number;
  unit: GameResultDataUnit.Milliseconds;
};

export type GameResultData =
  | GameResultDataRank
  | GameResultDataPoints
  | GameResultDataAttempts
  | GameResultDataMilliseconds
  | GameResultDataSolution;

/** Fill a cell with a value (Sudoku, 0hh1, 0hn0, Tentstrees, Numberblock) */
type GameMoveCellValue<T> = {
  x: number;
  y: number;
  value: T;
};

export type Direction = 'n' | 'ne' | 'e' | 'se' | 's' | 'sw' | 'w' | 'nw';

/** Startcell + direction (Wordfind, Crossword, Filippine) */
type GameMoveCellDirection = {
  x: number;
  y: number;
  value: string;
  direction: Direction;
};

/** Flip a cell (Duomoji) */
type GameMoveCell = {
  x: number;
  y: number;
};

/** Single value for the game (Headline, Quento, Wordle, Knightmove, Spellwise) */
type GameMoveSolution = {
  value: string;
  /** for Spellwise, Wordle, Quento */
  accepted?: boolean;
};

/** Multiple values for a move (Precies Vier) */
type GameMoveSolutionArray = {
  value: string[];
  accepted: boolean;
};

/** Smallest step you can take in a game. Fill a cell, find a word, etc. */
export type GameMoveData = ExternalTimerData & GameMoveDataWithoutTimer;

export type GameMoveDataWithoutTimer =
  | GameMoveCellValue<string>
  | GameMoveCellValue<number>
  | GameMoveCell
  | GameMoveSolution
  | GameMoveSolutionArray
  | GameMoveCellDirection;
