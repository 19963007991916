import { KeyboardLayoutBaseType } from 'Player/input';
import {
  AuthTypes,
  GameMenuActionTypes,
  Guidelines,
  Language,
  TextDirections,
  TimerConfig,
} from 'Shared/enums';
import { ToastLocation } from 'Shared/toast';
import type { NumberInputGamesConfig, PlayerConfig, WordGamesConfig } from 'Types/player-config';
import type { NumberInputGamePuzzleConfig, WordGamePuzzleConfig } from 'Types/puzzle';
import { NumberInputModeConfig } from 'Types/number-games';
import { PlayerExternalEvent } from 'Player/external-events';

export const defaultConfig: PlayerConfig = {
  authType: AuthTypes.None,
  direction: TextDirections.LTR,
  disableAutoStart: false,
  disableDefaultCss: false,
  disableExternalLinks: false,
  disabledGameButtons: [],
  enableDebugMode: false,
  enableHelpTools: false,
  enableTimer: TimerConfig.Off,
  feedbackForm: {
    showStars: true,
    showComments: true,
    showFollowUp: false,
  },
  finishModal: {
    show: true,
    emoticon: true,
    timer: true,
    stats: false,
    share: false,
    hideShareUrl: false,
    showFeedbackLink: false,
  },
  guidelines: Guidelines.Off,
  keyboardLayout: KeyboardLayoutBaseType.QWERTY,
  language: Language.nl,
  menuConfig: {
    poppedOutActions: [GameMenuActionTypes.help],
    menuType: 'button-title-menu',
    hideTitle: false,
    disableMenuButton: false,
  },
  usePersistentStorage: true,
  removeOldSaveGames: true,
  showAutoOnboarding: undefined,
  showShareModal: true,
  toastLocation: ToastLocation.Top,
  zoomLevel: 1.5,
  analytics: {
    emitEvents: [
      PlayerExternalEvent.Aborted,
      // PlayerExternalEvent.AdBreak,
      PlayerExternalEvent.Closed,
      PlayerExternalEvent.Completed,
      // PlayerExternalEvent.Move,
      PlayerExternalEvent.Paused,
      // PlayerExternalEvent.Progress,
      // PlayerExternalEvent.RequireLogin,
      // PlayerExternalEvent.RequireSubscription,
      PlayerExternalEvent.Share,
      PlayerExternalEvent.Started,
      // PlayerExternalEvent.Track,
      PlayerExternalEvent.VisitUrl,
    ],
    emitEventsImmediately: [
      PlayerExternalEvent.Aborted,
      PlayerExternalEvent.Closed,
      PlayerExternalEvent.Completed,
      PlayerExternalEvent.Started,
      PlayerExternalEvent.VisitUrl,
    ],
    emitBatchSize: 50,
    emitBatchTime: 5 * 60 * 1000, // 5 minutes
  },
};

export const defaultWordGameConfig: Partial<WordGamesConfig<WordGamePuzzleConfig>> = {
  wordGoal: 10,
  disableWikiLinks: false,
  showMaximumWordCount: false,
};

export const defaultNumberInputGameConfig: Partial<
  NumberInputGamesConfig<NumberInputGamePuzzleConfig>
> = {
  inputMode: NumberInputModeConfig.Cycle,
};
